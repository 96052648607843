import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import './HaircarePage.css';
import Header from './Header';
import ProductBox from './ProductBox';
import InfiniteScroll from 'react-infinite-scroll-component';
import debounce from 'lodash/debounce';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import BackButton from './BackButton';

const HaircarePage = () => {
    const { t, i18n } = useTranslation();
    const [products, setProducts] = useState([]);
    const [search, setSearch] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);


    const fetchProducts = async (page, searchTerm = '') => {
        try {
            const endpoint = searchTerm ? `${process.env.REACT_APP_API_BASE_URL}/api/main/haircare/search` : `${process.env.REACT_APP_API_BASE_URL}/api/main/haircare`;
            const response = await axios.get(endpoint, {
                params: {
                    page,
                    searchTerm // This will be ignored by the paginated endpoint
                },
            });

            const newProducts = response.data.products;

            const sortedProducts = newProducts.sort((a, b) => {
                const popA = a.popularityScore !== null ? a.popularityScore : 0;
                const popB = b.popularityScore !== null ? b.popularityScore : 0;

                if (popA !== popB) {
                    return popB - popA;
                }

                const priceDiffA = (a.prices.original - a.prices.discounted) || 0;
                const priceDiffB = (b.prices.original - b.prices.discounted) || 0;
                return priceDiffB - priceDiffA;
            });

            if (searchTerm) {
                setFilteredProducts((prevFilteredProducts) => {
                    if (page === 1) {
                        return sortedProducts;
                    } else {
                        return [...prevFilteredProducts, ...sortedProducts];
                    }
                });
            } else {
                setProducts((prevProducts) => {
                    if (page === 1) {
                        return sortedProducts;
                    } else {
                        return [...prevProducts, ...sortedProducts];
                    }
                });
                setFilteredProducts((prevFilteredProducts) => {
                    if (page === 1) {
                        return sortedProducts;
                    } else {
                        return [...prevFilteredProducts, ...sortedProducts];
                    }
                });
            }

            setHasMore(page < response.data.totalPages);
        } catch (error) {
            console.error('Error fetching products:', error);
        } 
    };

     // Debounce the search input
     const debouncedFetchProducts = useCallback(debounce((page, searchTerm) => {
        fetchProducts(page, searchTerm);
    }, 500), []); // Adjust the debounce delay as needed

        useEffect(() => {
            if (search.trim() === '') {
            setPage(1);
            setProducts([]);
            fetchProducts(1);
            } else {
            setPage(1);
            setHasMore(true);
            debouncedFetchProducts(1, search);
            }
        }, [search, debouncedFetchProducts]);

        useEffect(() => {
            if (page > 1) {
              fetchProducts(page, search);
            }
          }, [page, search]);
    
    
          const handleSearchChange = (e) => {
            setSearch(e.target.value);
        };

    return (
        <div className="container">
            <Helmet>
    <title>Haircare Products - Nourish Your Hair | GlamAlertz</title>
    <meta name="description" content="Find the best haircare products to nourish and protect your hair. Shop top brands now." />
    <title>منتجات العناية بالشعر - تغذية شعرك | GlamAlertz</title>
    <meta name="description" content="تجد أفضل منتجات العناية بالشعر لتغذية وحماية شعرك. تسوقي من أفضل العلامات التجارية الآن." />
    <meta name="robots" content="index, follow" />
</Helmet>

          <div className='header-menu-container'>
          <Header />
          </div>
          <BackButton />
            <div className="search-bar">
                <input 
                    type="text" 
                    placeholder={t('haircarePage.searchbar')} 
                    value={search} 
                    onChange={handleSearchChange} 
                />
            </div>
            <div className="skincare-products">
                <h1>{t('haircarePage.title')}</h1>
                <InfiniteScroll
                    dataLength={filteredProducts.length}
                    next={() => setPage((prevPage) => prevPage + 1)}
                    hasMore={hasMore}
                    loader={<p>{t('general.loading')}</p>}
                    endMessage={<p>{t('general.noMoreProducts')}</p>}
                >
                <div className="product-list">
                    {filteredProducts.map(product => (
                        <div key={uuidv4()} className="product">
                           <ProductBox product={product} />
                        </div>
                    ))}
                </div>
                </InfiniteScroll>
            </div>
        </div>
    );
};

export default HaircarePage;
