import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './BackButton.css'; // Import the updated CSS
import { useTranslation } from 'react-i18next';

const BackButton = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Ensure no re-renders or unnecessary API calls when returning to the previous page
    return () => {
      // Cleanup code if needed when leaving the page
    };
  }, [location]);

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="button-container">
      <button className="back-button" onClick={handleBack}>
        <span className="icon"></span>
      </button>
    </div>
  );
};

export default BackButton;