import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './BiggestDiscountsBanner.css';

const BiggestDiscountsBanner = () => {
    const { t } = useTranslation();

    return (
        <div className="banner-container">
            <div className="banner-content">
                <h1>{t('banner.title')}</h1>
                <p>{t('banner.description')}</p>
                <Link to="/most-discounted">
                    <button className="banner-button">{t('banner.buttonText')}</button>
                </Link>
            </div>
        </div>
    );
};

export default BiggestDiscountsBanner;